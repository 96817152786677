var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("green-container", [
    _c("div", { staticClass: "linkCard" }, [
      _c("div", { staticClass: "linkCard__wrap" }, [
        _c(
          "ul",
          { staticClass: "linkCard__list" },
          _vm._l(_vm.linkCards, function (item) {
            return _c(
              "li",
              { staticClass: "linkCard__item" },
              [
                _c(
                  "link-wrap",
                  { attrs: { link: item.link } },
                  [
                    item.image
                      ? _c("image-tag", {
                          staticClass: "linkCard__itemImage",
                          attrs: { image: item.image, definition: "teaser" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "linkCard__itemInner" }, [
                      _c("h5", { staticClass: "linkCard__item--headline" }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                      _vm._v(" "),
                      item.text
                        ? _c("p", [_vm._v(_vm._s(item.text))])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }