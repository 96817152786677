






































import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {State} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import GreenContainer from '../../../Component/Layout/GreenContainer/GreenContainer.vue';
import ImageTag from '../../../Component/Misc/Image/ImageTag/ImageTag.vue';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'LinkCard',
    components: {ImageTag, LinkWrap, GreenContainer},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        linkCards(): Array<State>
        {
            return this.context.data.get('cards', []);
        }
    }
};
